<template>
<el-row>
  <el-col :span="24" style="background: #F7F6F6;">
    <div class="main"> 
        <div class="title">论文投稿 </div>
            <div class="content">
               <MeetingInfoVue></MeetingInfoVue>
            <div class="contribution">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm">  
                <el-row> 
                    <el-col :span="23"> 
                        <el-form-item label="论文标题" prop="title"> 
                            <el-input placeholder="请输入论文标题" v-model="ruleForm.title"></el-input> 
                        </el-form-item>
                    </el-col> 
                    <el-col :span="23"> 
                        <el-form-item label="关键字" prop="key_word"> 
                            <el-input placeholder="请输入论文关键字" v-model="ruleForm.key_word"></el-input> 
                        </el-form-item>
                    </el-col> 
                    <el-col :span="13"> 
                        <el-form-item label="主题方向" prop="theme"> 
                            <el-select v-model="ruleForm.theme" placeholder="请选择主题方向">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                           
                        </el-form-item>
                    </el-col>
                    <el-col :span="23">  
                        <el-form-item label="上传论文附件">   
                            <div class="upload"> 
                                上传论文或者摘要<span>（必传）</span>
                                <el-upload
                                    class="upload_bt"
                                    :action="url"
                                    :on-exceed="handleExceed"
                                    :on-preview="handlePreview"
                                    :on-remove="handleRemove"
                                    :before-remove="beforeRemove"
                                    :on-success = "uploadSuccess"
                                    name="fileupload"   
                                    :data={item_id:this.ruleForm.u_id,m_id:this.ruleForm.m_id,type_id:8}
                                    :limit="1" 
                                    :file-list="fileList">
                                    <img src="../../public/static/images/bt1.png" width="100"> 
                                </el-upload>
                            </div> 
                        </el-form-item>
                        <div class="line"></div>
                    </el-col> 
                    <el-col :span="23" class="author"> 
                        <strong>[作者信息]</strong> 
                        <span> *请完善所有信息，以确保录用通知无误。</span>
                    </el-col>
                    <el-col :span="22">  
                        <el-form-item label="作者姓名" prop="author_name">  
                            <el-input placeholder="请输入作者姓名" v-model="ruleForm.author_name"></el-input>    
                        </el-form-item> 
                    </el-col>
                    <el-col :span="22"> 
                        <el-form-item label="联系地址" prop="addres"> 
                           <el-input placeholder="请输入联系地址" v-model="ruleForm.addres"></el-input>
                        </el-form-item> 
                    </el-col>
                    <el-col :span="22"> 
                        <el-form-item label="手机号码" prop="s_phone">  
                            <el-input placeholder="请输入手机号码" v-model="ruleForm.s_phone"></el-input>   
                        </el-form-item> 
                    </el-col>
                    <el-col :span="22"> 
                        <el-form-item label="学校/单位" prop="s_company"> 
                           <el-input placeholder="请输入学校/单位" v-model="ruleForm.s_company"></el-input>
                        </el-form-item> 
                    </el-col>
                      <el-col :span="22"> 
                        <el-form-item label="工作邮箱" prop="email"> 
                            <el-input placeholder="请输入工作邮箱" v-model="ruleForm.email"></el-input>   
                        </el-form-item> 
                    </el-col>   
                    <el-col :span="22"> 
                        <el-form-item label="报告人" prop="reporter"> 
                           <el-input placeholder="请输入报告人" v-model="ruleForm.reporter"></el-input>
                        </el-form-item> 
                    </el-col>  
                    <el-col :span="22"> 
                        <el-form-item label="备注" prop="s_marks">
                            <el-input type="textarea" :rows="5" placeholder="请输入备注" v-model="ruleForm.s_marks"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="22" :offset="2"> 
                        <el-form-item> 
                            <el-button class="bt" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                        </el-form-item>
                    </el-col>  
                </el-row>
                </el-form>
            </div>
        </div> 
    </div>
    </el-col>
</el-row>
</template>

<script>
import MeetingInfoVue from '../components/MeetingInfo'; 
import {meetingSubmissionSave,getMeetingSubmissionOne,meetingUploadDel} from '../api/index';


export default {
    components: {
        MeetingInfoVue         
    },
  name: 'ApplicantsVue',
   data() { 
      return { 
        fileList:[], 
        url:'/api/SellerMeeting/meeting_data_upload',
        options: [{
          value: '新能源新材料技术',
          label: '新能源新材料技术'
        }, {
          value: '电子信息与人工智能技术',
          label: '电子信息与人工智能技术'
        }, {
          value: '智能制造技术',
          label: '智能制造技术'
        } 
        ],
        ruleForm: {
          s_id: '',
          u_id: '',
          m_id: '',
          title: '',
          key_word: '',
          author_name: '',  
          addres: '',
          s_phone: '',
          s_company: '',
          email: '',
          data: '',
          s_marks: '',
          reporter:'',
          theme:'',
        },
        rules: {
          title: [
            { required: true, message: '请输入论文标题', trigger: 'blur' },
            { min: 3, max: 1000, message: '长度在 3 到 1000个字符', trigger: 'blur' }
          ],
          key_word: [
            { required: true, message: '请输入关键字', trigger: 'blur' }
          ],
          author_name: [
            { required: true, message: '请输入作者姓名', trigger: 'blur' }
          ], 
          addres: [
            { required: true, message: '请输入联系地址', trigger: 'blur' }
          ],
          email: [
            { required: true, message: '请输入工作邮箱', trigger: 'blur' }
          ],
          s_phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' }
          ],
          s_company: [
            {required: true, message: '请输入学校/单位', trigger: 'blur' }
          ] 
        }
      };
    }, 
    methods:{
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        uploadSuccess(response) {
            this.fileList.push(response.result); 
            this.$message.success({
                    showClose: true,
                    message: '论文附件上传成功!'                    
                }) 
        }, 
        handleExceed(files, fileList) {
            this.$message.warning(`请先删除，再上传!`); 
        },
        beforeRemove(file, fileList) {  
            var files = this.fileList;
            return  this.$confirm(`确定删除 ${ file.name }？`,{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{ 
                    meetingUploadDel({file_id:files[0].file_id,branch:"del_file_upload"}) 
                }) 
        },  
        submitForm(formName) {  
            this.$refs[formName].validate((valid) => { 
                if (valid) { 
                    meetingSubmissionSave(this.ruleForm).then(res=>{ 
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        location.reload()
                    }) 
                }
                return false;  
            }); 
        }, 
    },
    mounted(){  
        if(this.$store.getters.getUid==''){ //没有报名参会
            this.$message.success({
                    showClose: true,
                    message: '请先报名参会!',
                    type: 'warning'
                }) 
            this.$router.push({
                path: '/applicants?m_id='+this.$store.getters.getMid,
            });
        } 
        getMeetingSubmissionOne({//检查是否登录
            'm_id':this.$store.getters.getMid,
            'u_id':this.$store.getters.getUid
        }).then(res=>{ 
            this.ruleForm = res.data.result.form  
            this.ruleForm.m_id = this.$store.getters.getMid; //防止空值
            this.ruleForm.u_id = this.$store.getters.getUid; 
            if(res.data.result.doc!='') this.fileList.push(res.data.result.doc);   
        }) 
     
    }
}
</script> 
 
<style scoped> 
.main{
    width: 100%;
    min-height: 300px;
    background: #ffffff;
    margin: 0px auto; 
    padding-bottom: 100px;
}
.main .title{
    width: 100%;
    line-height: 30px;
    background: #467DEB;
    text-align: center;
    color:#ffffff;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    font-weight: bolder;
    font-size: 14px;
    margin-bottom: 20px;
}
.main .content{
    padding: 0 10px;
}
.main .meeting_msg{
    padding: 0 10px;
    text-align: left;
}
.meeting_msg span{
    font-weight: bolder;
    color: #366CD7; 
}
.meeting_msg .time{
    color: #FF6600;
}  
.meeting_msg p{
    margin:0px;
    line-height: 32px;
    font-size: 14px;
}
.meeting_msg .msg_title{
    margin-top:20px;
}
.contribution{
    width: 100%;
    min-height: 300px;
    margin: 20px auto;
}
.upload_bt,.upload_bt1{
    width: 100%;
    margin: 0px auto;
    margin-top: 20px;
}
.upload span{
    color: red;
}
.line{
    width: 90%;
    height: 2px;
    margin:0 auto;
    border-bottom: solid 1px #eeeeee;
}
.author{
    margin: 10px 0;
    text-align: left;
    line-height: 50px;
    font-size: 12px;

}
.author strong{
    color:#366CD7;
}
.author span{
    color:#FF6600;
}

.bt{
    width:70%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-weight: bolder;
    line-height: 28px;
    padding-bottom: 10px;
}
</style>
