var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
if (!flag) { 
    location.href ='http://frontiermeeting2023.gd58hr.com/#/';
} 

import { createApp } from 'vue'
import App from './App.vue'
import store from "./store"
import 'element-plus/dist/index.css'
import element from 'element-plus'
import router from './router'

const app = createApp(App)
app.use(router).use(store).use(element).mount('#app')
